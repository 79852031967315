import * as Sentry from "@sentry/browser";

import {
  addSingleAlert,
  clearAlerts,
  persistNotificationError,
  createAPIError
} from "redux/Notification/NotificationModule";

import {
  getUserChecks,
  authenticateChecks
} from "utilities/authenticationCheck";
import { processFailToFetch } from "utilities/handleFetchErrors";

import { openModal, closeModal } from "redux/Modal/ModalModule";
import { setProjectAsReadOnly } from "redux/Project/actions";
import { resumeProject } from "redux/Project/thunks";
import { getNextAvailOrCurrentRun } from "redux/Project/selectors";
import { fetchClassesByInstructor } from "redux/Instructor/thunks";
import { NOTIFICATION_MODAL } from "routes/Modal/ModalTypes";

import * as actions from "./actions";

// import { push } from 'react-router-redux';
import history from "../../history";
// import routes from 'routes/rout	es';
import { RESET_PASSWORD_MODAL } from "../../routes/Modal/ModalTypes";
import {
  // classroomCheckBool,
  // firstRunCheckBool,
  allChecksPassed
} from "./selectors";

let alertId = 1;

export const logout = () => async (dispatch) => {
  dispatch(actions.doLogout(true));
  dispatch(setProjectAsReadOnly(false));
  window.sessionStorage.clear();
  history.push("/authentication");
};

export const getMasterControlUserInfo =
  (userId) => async (dispatch, getState) => {
    if (userId && userId !== 0) {
      try {
        const payload = {
          userId: getState().auth.userId
        };
        const response = await fetch(
          `${getState().app.baseURL}JsonUserSvc.svc/masterControlUser`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=utf-8"
            },
            method: "post",
            body: JSON.stringify(payload)
          }
        );
        // http status
        const { status } = response;
        // wait for json respone to come back
        const json = await response.json();

        if (!response.ok) {
          // bad http response
          const alert = {
            id: 20,
            type: "danger",
            event: "Get MasterControlUser Error",
            headline: "HTTP call error in getMasterControlUser api call",
            message: `  api call: getMasterControlUser : response status : ${status.toString()}`
          };
          const notificationData = {
            worker: "thunk",
            week: 0,
            info: `  api call: getMasterControlUser : response status : ${status.toString()}`
          };
          dispatch(addSingleAlert(alert, notificationData, 0, true));
          dispatch(
            actions.invalidLogin(`${status.toString()} in mastercontrol db`)
          );
          dispatch(
            createAPIError({
              type: "CREATE_API_ENTITY_ERROR",
              errorType: "warning",
              userId,
              message: "Error creating api"
            })
          );
          return false;
        } else if (json.status === "error") {
          // have json and status is ok
          const alert = {
            id: 20,
            type: "danger",
            event: "Get MasterControlUser Error",
            headline: "Error : missing user data in the mastercontrol table",
            message: `  Error : missing user data in the mastercontrol table : response status : ${json.statusMessage}`
          };
          const notificationData = {
            worker: "thunk",
            week: 0,
            info: `  Error : missing user data in the mastercontrol table : response status : ${json.statusMessage}`
          };
          dispatch(addSingleAlert(alert, notificationData, 0, true));
          dispatch(actions.invalidLogin(json.statusMessage));
          return false;
        }
        await dispatch(
          actions.receiveMasterControlUser(JSON.parse(json.payload))
        );
        if (getState().auth.productId === 45) {
          dispatch(
            openModal(
              NOTIFICATION_MODAL,
              "Alert: You are using an account for the agile simulation.  Please go to http://agile.simproject.com to access this simulation.",
              "modal-medium"
            )
          );
          return false;
        }
        return true;
      } catch (e) {
        processFailToFetch(
          e,
          getState(),
          dispatch,
          addSingleAlert,
          "processTaskAssignmentShift"
        );

        return false;
      }
    }
    const alert = {
      id: 2002,
      type: "danger",
      event: "Login error during getmastercontrol user",
      headline: "Error : User Id not set in state",
      message: `Error :  user id is:  ${userId}`
    };
    const notificationData = {
      worker: "thunk",
      week: 0,
      info: `Error :  user id is:  ${userId}`
    };
    dispatch(addSingleAlert(alert, notificationData, 0, true));
    dispatch(actions.invalidLogin("Error : User Id not set in state"));
    return false;
  };
export const fetchRuns =
  (userId) =>
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  async (dispatch, getState) => {
    // that the API call is starting.
    try {
      const payload = {
        userId
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonUserSvc.svc/studentRuns`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "warning",
          event: "Accept License Error",
          headline: "Error after accept license fetch",
          message: `User Id: ${userId}  acceptlicense : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `User Id: ${userId}  acceptlicense : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            userId,
            message: "Error creating acceptlicense api"
          })
        );
        return false;
      } else if (json.status === "error") {
        if (json.statusMessage === "License Expired") {
          // there won't be any runs.
          actions.receiveSuccess(JSON.parse(json.payload));
          return false;
        }
        dispatch(actions.receiveError(json));
        return false;
      }
      await dispatch(actions.receiveSuccess(JSON.parse(json.payload)));
      return true;
    } catch (e) {
      processFailToFetch(e, getState(), dispatch, addSingleAlert, "fetchRuns");

      return false;
    }
  };

export const createStudents = (studentData) => async (dispatch, getState) => {
  try {
    const payload = {
      studentList: studentData
    };
    const response = await fetch(
      `${getState().app.mbrshpURL}bulkSetupNewUsers`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // http status
    const { status } = response;
    // wait for json respone to come back
    await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "warning",
        event: "createStudents",
        headline: "Error after createStudents fetch",
        message: `createStudents : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `createStudents : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(persistNotificationError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          id: 0,
          message: "Error creating createStudents api"
        })
      );
      return false;
    }
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "createStudents"
    );

    return false;
  }
};
// this is the main login function, it dispatches 2 functions
// getmastercontrol user which contains user expiration Date
// then fetch runs which contains license expiration Date
// if any of the dates are expired, user cannot move past login form
export const login =
  (fromRegistration = false) =>
  async (dispatch, getState) => {
    // clear data from previous user/session if any

    const { userId } = getState().auth;
    if (userId && userId > 0) {
      try {
        dispatch(setProjectAsReadOnly(false));

        const mcResponse = await dispatch(getMasterControlUserInfo(userId));
        if (mcResponse === true) {
          if (
            getState().auth.userType === "Instructor" ||
            (Object.prototype.hasOwnProperty.call(
              getState().auth,
              "studentInfo"
            ) &&
              getState().auth.studentInfo.StudentType === 89)
          ) {
            const iResponse = await dispatch(
              fetchClassesByInstructor(getState().auth.user.UserId)
            );

            if (iResponse === true) {
              history.push("/instructor-dashboard");
            }
            return true;
          } else {
            // else if (getState().auth.userType === "Administrator") {
            //   history.push("/admin");
            //   return true;
            // }
            // expiration date is passed back in ISO 8601 standard
            // need to convert it to javascript format to compare
            const rightNow = new Date();
            const expirDate = new Date(getState().auth.user.ExpirationDate);

            if (rightNow < expirDate) {
              // ok to proceed
              const resStatus = await dispatch(
                fetchRuns(getState().auth.userId)
              );

              if (resStatus) {
                if (getState().auth.message === "License Expired") {
                  const alert = {
                    id: (alertId += 1),
                    type: "danger",
                    headline: "Error",
                    event: "login",
                    message:
                      "Your SimProject license has expired.  Please contact us at support@simulationpl.com for assistance"
                  };
                  const notificationData = {
                    info: `userId: ${getState().auth.userId} License Expired`,
                    worker: getState().auth.user.UserName,
                    week: 0
                  };
                  dispatch(addSingleAlert(alert, notificationData, 0, true));
                  dispatch(actions.invalidLogin("licenseexpired"));

                  return false;
                }
                const licExpirDate = new Date(
                  getState().auth.studentInfo.LicenseExpirationDate
                );

                if (
                  rightNow < licExpirDate ||
                  (Object.prototype.hasOwnProperty.call(
                    getState().auth,
                    "runDetails"
                  ) &&
                    getState().auth.runDetails.length > 0)
                ) {
                  if (fromRegistration === true) {
                    history.push("/welcome");
                    return true;
                  }
                  // ok to proceed
                  if (allChecksPassed(getState()) === false) {
                    history.push("/welcome");
                    return true;
                  }
                  const currentRun = getNextAvailOrCurrentRun(getState());
                  if (currentRun !== undefined && currentRun !== {}) {
                    if (currentRun.RunId === 0) {
                      dispatch(
                        actions.setLoginMessage("Please start a new run.")
                      );
                      history.push("/projects");
                      return true;
                    } else if (
                      currentRun.RunStatus === "Complete!" ||
                      currentRun.RunStatus === "Archived" ||
                      currentRun.RunStatus === "Abandoned!"
                    ) {
                      dispatch(
                        actions.setLoginMessage(
                          "You have completed all of your licensed executions.  Redirecting to the review run page.  If you need additional runs, please contact support@simulationpl.com to adjust your license (may be cost involved)"
                        )
                      );
                      history.push("/projects");
                      return true;
                    }
                  }

                  if (
                    getState().auth.studentInfo.ClassroomId &&
                    parseInt(getState().auth.studentInfo.ClassroomId, 10) ===
                      587
                  ) {
                    let headline = "";
                    let message = "";
                    if (getState().auth.user.UserName.substr(0, 4) === "SHSU") {
                      headline = "Close all individual simproject tabs.";
                      message =
                        "You're logging in with a team account and your individual execution data may get overwritten due to browser tab data sharing issues.  Please close all other simproject tabs";
                    } else {
                      headline = "Close all team simproject tabs.";
                      message =
                        "You're logging in with an individual account and your team execution data may get overwritten due to browser tab data sharing issues.  Please close all other simproject tabs";
                    }
                    const alert = {
                      id: 33,
                      type: "danger",
                      event: "Simproject Warning",
                      headline,
                      message
                    };
                    const notificationData = {
                      worker: "thunk",
                      week: 0,
                      info: message
                    };
                    dispatch(addSingleAlert(alert, notificationData, 0, true));
                  }
                  Promise.all([
                    // find the active run and resume it. - don't dismiss alert yet
                    dispatch(
                      resumeProject(currentRun.RunId, getState().auth.productId)
                    )
                  ]);
                  return true;
                }
                return false;
              }
              const alert = {
                id: (alertId += 1),
                type: "danger",
                headline: "Error",
                event: "login",
                message:
                  "Your License  has expired.  Please contact us at support@simulationpl.com for assistance"
              };
              const notificationData = {
                info: `userId: ${getState().auth.userId} License Expired`,
                worker: getState().auth.user.UserName,
                week: 0
              };
              dispatch(addSingleAlert(alert, notificationData, 0, true));
              dispatch(actions.expiredAccount("License Expired"));
              return false;
            }
          }

          const alert = {
            id: (alertId += 1),
            type: "danger",
            headline: "Error",
            event: "login",
            message:
              "There are issues with your account.  Please contact us at support@simulationpl.com for assistance"
          };
          const notificationData = {
            info: `userId: ${getState().auth.userId} Account issues`,
            worker: getState().auth.user.UserName,
            week: 0
          };
          dispatch(addSingleAlert(alert, notificationData, 0, true));
          dispatch(actions.expiredAccount("Account issues"));
          return false;
        }
        // cannot find user account in the database
        // already thrown an error in api call thunk
        // TODO - anything else needed here?
        //
      } catch (error) {
        Sentry.captureEvent({
          message: `Server exeception ${error} ${error.message}`,
          stacktrace: [error.stacktrace]
        });
        const alert = {
          id: (alertId += 1),
          type: "danger",
          headline: "Error",
          event: "login",
          message: `There is an error in the application.  Please send a screenshot of this to support : ${error}`
        };
        const notificationData = {
          info: `userId: ${
            getState().auth.userId
          } There is an error in the application.  Please send a screenshot of this to support : ${error}`,
          worker: getState().auth.user.UserName,
          week: 0
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        return false;
      }
    }
    return false;
  };

export const authenticate =
  (username, password) => async (dispatch, getState) => {
    try {
      window.sessionStorage.clear();
      dispatch(actions.requestLogin()); // sets is fetching =
      const payload = {
        username,
        password: password.trim() // remove extra spaces from copy/paste errors
      };
      if (username === "") {
        return false;
      }
      let alert = {};
      const response = await fetch(`${getState().app.mbrshpURL}login`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      });

      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad
        alert = {
          id: 20,
          type: "danger",
          event: "Authenticate Error",
          headline: "Error accessing the authentication api",
          message: `Username: ${username}  fetch : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `Username: ${username}  authenticate : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            username,
            message: "Entity error while creating api"
          })
        );
        dispatch(
          actions.invalidLogin(`bad http response: ${status.toString()}`)
        );
        return false;
      }

      const authObject = authenticateChecks(json.authenticateUserResult);
      // if (authObject.UserId === 248) {
      //   return false;
      // }

      switch (authObject.statusCode) {
        case "invaliduser":
          dispatch(actions.invalidLogin(authObject));
          return false;
        case "needsregistration":
          if (window.location.pathname !== "/registration") {
            await dispatch(
              actions.setUserForRegistration(json.authenticateUserResult)
            );
            dispatch(actions.invalidLogin("needsregistration"));
            alert = {
              id: 5,
              type: "info",
              headline: "Complete Registration",
              event: "login",
              message:
                "Switching you to registration, please complete before continuing."
            };
            dispatch(addSingleAlert(alert, {}, 0, false));
            history.push("/registration");
            return false;
          }
          await dispatch(actions.receiveLogin(json.authenticateUserResult));
          return true;
        case "resetpassword":
          await dispatch(clearAlerts());

          if (window.location.pathname !== "/registration") {
            alert = {
              id: 33,
              type: "warning",
              headline: "Please change your password",
              event: "login",
              message:
                "Your temporary password needs to be changed before continuing."
            };

            dispatch(addSingleAlert(alert, {}, 3000, false));
            await dispatch(
              actions.setUserForPasswordReset(json.authenticateUserResult)
            );
            dispatch(openModal(RESET_PASSWORD_MODAL, {}, "modal-medium"));
            dispatch(actions.invalidLogin("resetpassword"));

            return false;
          }
          await dispatch(actions.receiveLogin(json.authenticateUserResult));
          return true;

        case "incorrectpassword":
          dispatch(actions.invalidLogin(authObject));
          return false;
        default:
          await dispatch(actions.receiveLogin(json.authenticateUserResult));

          if (window.location.pathname === "/registration") {
            dispatch(login());
          }

          return true;
      }
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "authenticate"
      );
      return false;
    }
  };

export const acceptLicense = (userId) => async (dispatch, getState) => {
  try {
    const payload = {
      userId: getState().auth.userId,
      productId: getState().auth.productId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonUserSvc.svc/acceptLicense`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "warning",
        event: "Accept License Error",
        headline: "Error after accept license fetch",
        message: `User Id: ${userId}  acceptlicense : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `User Id: ${userId}  acceptlicense : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(persistNotificationError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          userId,
          message: "Error creating acceptlicense api"
        })
      );
      return false;
    } else if (json.status === "error") {
      const alert = {
        id: 20,
        type: "warning",
        event: "Accept License Error",
        headline: "Error in acceptlicense response",
        message: `User Id: ${userId}  acceptlicense : response  : ${json.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `User Id: ${userId}  acceptlicense : response  : ${json.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.invalidLogin(json.statusMessage));
      return false;
    }
    dispatch(actions.licenseAcceptedSuccess(JSON.parse(json.payload)));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "acceptLicense"
    );
    return false;
  }
};

export const updateCheckListItem =
  (userId, controlNumber, controlType, isSet) => async (dispatch, getState) => {
    try {
      const payload = {
        userId,
        controlNumber,
        controlType,
        isSet
      };

      const response = await fetch(
        `${getState().app.baseURL}JsonUserSvc.svc/updateUserControl`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "warning",
          event: "updateUserControl Error",
          headline: "Error after updateUserControl fetch",
          message: `User Id: ${userId}  updateUserControl : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `User Id: ${userId}  updateUserControl : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            userId,
            message: "Error creating updateUserControl api"
          })
        );
        return false;
      } else if (json.status === "error") {
        const alert = {
          id: 20,
          type: "warning",
          event: "updateUserControl Error",
          headline: "Error after updateUserControl fetch",
          message: `User Id: ${userId}  updateUserControl : response  : ${json.statusMessage}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `User Id: ${userId}  updateUserControl : response  : ${json.statusMessage}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(actions.receiveError(json.statusMessage));
        return false;
      }
      dispatch(actions.updateUserControlSuccess(json.payload));
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "updateCheckListItem"
      );
      return false;
    }
  };
export const getUser = (username) => async (dispatch, getState) => {
  try {
    const payload = {
      username,
      updateLastActivity: true
    };
    const response = await fetch(
      `${getState().app.mbrshpURL}getUserByUsername`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;

    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "warning",
        event: "getUserByUsername Error",
        headline: "Error after getUserByUsername fetch",
        message: `username: ${username}  getUserByUsername : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `username: ${username}  getUserByUsername : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(persistNotificationError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          username,
          message: "Error creating updateUserControl api"
        })
      );
      return false;
    }
    const checkObj = getUserChecks(json.getUserByUsernameResult);
    if (checkObj.status === "success") {
      dispatch(actions.receiveUser(checkObj));
      return true;
    }
    dispatch(actions.invalidUser(checkObj));
    return false;
  } catch (e) {
    processFailToFetch(e, getState(), dispatch, addSingleAlert, "getUser");

    return false;
  }
};
export const forgotPassword =
  (username, passwordAnswer) => async (dispatch, getState) => {
    try {
      const payload = {
        username,
        passwordAnswer
      };

      const response = await fetch(
        `${getState().app.mbrshpURL}forgotPassword`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (json.forgotPasswordResult.status === "success") {
        const alert = {
          id: 13,
          type: "success",
          event: "forgotPassword",
          headline: "Password Recovery",
          message: `The new password has been generated, please check your email.`
        };
        const notificationData = {
          info: `The new password has been generated, please check your email.`,
          worker: "Everyone",
          week: 0
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
      } else if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "warning",
          event: "forgotPassword Error",
          headline: "Error after forgotPassword fetch",
          message: `The new password cannot be generated: ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `username: ${username}  forgotPassword : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            username,
            message: "Error creating forgotPassword api"
          })
        );
        return false;
      } else if (json.forgotPasswordResult.status === "error") {
        const alert = {
          id: 20,
          type: "warning",
          event: "forgotPassword Error",
          headline: "There is an issue with your request",
          message: `The new password cannot be generated: ${json.forgotPasswordResult.statusMessage}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `username: ${username}  forgotPassword :  status : ${json.forgotPasswordResult.statusMessage}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(
          actions.forgotPasswordFailure(json.forgotPasswordResult.statusMessage)
        );
        return false;
      }
      dispatch(
        actions.forgotPasswordSuccess(
          JSON.parse(json.forgotPasswordResult.payload)
        )
      );
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "forgotPassword"
      );
      return false;
    }
  };

export const resetPassword =
  (username, passwordAnswer, oldPassword, newPassword) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearAlerts());
      const payload = {
        username,
        passwordAnswer,
        oldPassword,
        newPassword
      };
      const response = await fetch(`${getState().app.mbrshpURL}resetPassword`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      });

      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "warning",
          event: "resetPassword Error",
          headline: "Error after resetPassword fetch",
          message: `username: ${username}  resetPassword : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `username: ${username}  resetPassword : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            username,
            message: "Error creating resetPassword api"
          })
        );
        return false;
      } else if (json.resetPasswordResult.status === "error") {
        const alert = {
          id: 20,
          type: "danger",
          event: "resetPassword Error",
          headline: "Error in resetpassword response",
          message: `username: ${username}  resetPassword :  status : ${json.resetPasswordResult.statusMessage}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `username: ${username}  resetPassword :  status : ${json.resetPasswordResult.statusMessage}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(
          actions.resetPasswordFailure(json.resetPasswordResult.statusMessage)
        );
        return false;
      }
      const pd = {
        username,
        password: newPassword,
        payload: JSON.parse(json.resetPasswordResult.payload)
      };
      await dispatch(actions.resetPasswordSuccess(pd));
      dispatch(closeModal());

      const alert = {
        id: 12,
        type: "success",
        headline: "resetPassword Changed",
        event: "resetPassword change",
        message:
          "Your password has been changed.  Please wait while we log you in to SimProject."
      };
      const notificationData = {
        info: `userId: ${getState().auth.userId} Password Changed`,
        worker: getState().auth.user.UserName,
        week: 0
      };
      dispatch(addSingleAlert(alert, notificationData, 3000, true));

      dispatch(login(pd.username, pd.password));
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "resetPassword"
      );
      return false;
    }
  };

export const register =
  (
    username,
    passwordQuestion,
    passwordAnswer,
    oldPassword,
    newPassword,
    orderNo
  ) =>
  async (dispatch, getState) => {
    try {
      const payload = {
        username: username.trim(),
        userId: getState().auth.userId,
        productId: 38, // TODO fix this from hard code
        newPasswordQuestion: passwordQuestion,
        newPasswordAnswer: passwordAnswer.trim(),
        oldPassword: oldPassword.trim(),
        newPassword: newPassword.trim(),
        orderNo
      };
      const response = await fetch(`${getState().app.mbrshpURL}register`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      });
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "warning",
          event: "register Error",
          headline: "Error after register fetch",
          message: `username: ${username}  register : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `username: ${username}  register : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            username,
            message: "Error creating register api"
          })
        );
        return false;
      }
      const regObject = authenticateChecks(json.registerResult);
      if (regObject.status === "success") {
        await dispatch(actions.registerSuccess(regObject));
        if (getState().auth.userType !== "Instructor") {
          await dispatch(fetchRuns(getState().auth.userId));
          await dispatch(
            updateCheckListItem(
              getState().auth.userId,
              2,
              "CHANGE_PASSWORD",
              true
            )
          );
        }
        await dispatch(actions.receiveLogin(json.registerResult));

        dispatch(login(true));
        return true;
      }

      dispatch(actions.registerFailure(regObject));
      return false;
    } catch (e) {
      processFailToFetch(e, getState(), dispatch, addSingleAlert, "register");

      return false;
    }
  };

export const getAvailableClasses = () => async (dispatch, getState) => {
  try {
    const response = await fetch(
      `${getState().app.baseURL}JsonClassroomSvc.svc/availableClasses`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post"
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "warning",
        event: "getAvailableClasses Error",
        headline: "Error after getAvailableClasses fetch",
        message: `  getAvailableClasses : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `  getAvailableClasses : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(persistNotificationError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",

          message: "Error creating getAvailableClasses api"
        })
      );
      return false;
    } else if (json.status === "error") {
      const alert = {
        id: 20,
        type: "warning",
        event: "getAvailableClasses Error",
        headline: "Error in the getAvailableClasses response",
        message: `  getAvailableClasses :  status : ${json.status}  message : ${json.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `  getAvailableClasses :  status : ${json.status}  message : ${json.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.getAvailableClassesError(json.statusMessage));
      return false;
    }
    dispatch(actions.getAvailableClassesSuccess(JSON.parse(json.payload)));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "getAvailableClasses"
    );
    return false;
  }
};

export const placeInClassroom =
  (classStudentDBId, classId, runDBIdList) => async (dispatch, getState) => {
    try {
      const payload = {
        classStudentDBId,
        classId,
        studentRunList: runDBIdList
      };
      // call the api
      const response = await fetch(
        `${getState().app.baseURL}JsonUserSvc.svc/placeInClassroom`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "warning",
          event: "placeInClassroom Error",
          headline: "Error after getAvailableClasses fetch",
          message: `  placeInClassroom : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `  placeInClassroom : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(persistNotificationError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",

            message: "Error creating placeInClassroom api"
          })
        );
        return false;
      } else if (json.status === "error") {
        const alert = {
          id: 20,
          type: "warning",
          event: "placeInClassroom  Error",
          headline: "Error in placeInClassroom response",
          message: `  placeInClassroom :  status : ${json.status}  message : ${json.statusMessage}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `  placeInClassroom :  status : ${json.status}  message : ${json.statusMessage}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(actions.placeInClassroomError(json.statusMessage));
        return false;
      }
      await dispatch(actions.placeInClassroomSuccess(json.payload));
      await dispatch(
        updateCheckListItem(getState().auth.userId, 5, "ASSIGN_CLASSROOM", true)
      );
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "placeInClassroom"
      );
      return false;
    }
  };

export const getSystemMessages = () => async (dispatch, getState) => {
  try {
    // call the api
    const response = await fetch(
      `${getState().app.baseURL}JsonUserSvc.svc/systemMessages`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post"
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "warning",
        event: "get System Message Error",
        headline: "Error after getSystemMessage fetch",
        message: `  getSystemMessage : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `  getSystemMessage : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(persistNotificationError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",

          message: "Error creating getSystemMessage api"
        })
      );
      return false;
    } else if (json.getSystemMessageResult.status === "error") {
      const alert = {
        id: 20,
        type: "warning",
        event: "getSystemMessage  Error",
        headline: "Error in getSystemMessage response",
        message: `  getSystemMessage :  status : ${json.getSystemMessageResult.status}  message : ${json.getSystemMessageResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `  getSystemMessage :  status : ${json.ugetSystemMessageResult.status}  message : ${json.getSystemMessageResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(
        actions.getMessageFailure(json.getSystemMessageResult.statusMessage)
      );
      return false;
    }
    dispatch(actions.getMessageSuccess(json.getSystemMessageResult.payload));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "getSystemMessages"
    );
    return false;
  }
};
